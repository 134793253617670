import React, { useEffect, useRef } from "react";

const HeartCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      (navigator.userAgent || navigator.vendor || (window as any).opera).toLowerCase()
    );

    const koef = isMobile ? 0.5 : 1;

    const resizeCanvas = () => {
      canvas.width = koef * window.innerWidth;
      canvas.height = koef * window.innerHeight;
      ctx.fillStyle = "rgba(0,0,0,1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    let loaded = false;

    const init = () => {
      if (loaded) return;
      loaded = true;

      const width = canvas.width;
      const height = canvas.height;

      const rand = Math.random;
      ctx.fillStyle = "rgba(0,0,0,1)";
      ctx.fillRect(0, 0, width, height);

      const heartPosition = (rad: number) => [
        Math.pow(Math.sin(rad), 3),
        -(15 * Math.cos(rad) -
          5 * Math.cos(2 * rad) -
          2 * Math.cos(3 * rad) -
          Math.cos(4 * rad)),
      ];

      const scaleAndTranslate = (
        pos: number[],
        sx: number,
        sy: number,
        dx: number,
        dy: number
      ) => [dx + pos[0] * sx, dy + pos[1] * sy];

      const traceCount = isMobile ? 10 : 30;
      const pointsOrigin: number[][] = [];
      const dr = isMobile ? 0.3 : 0.1;

      for (let i = 0; i < Math.PI * 2; i += dr)
        pointsOrigin.push(scaleAndTranslate(heartPosition(i), 210, 13, 0, 0));

      const heartPointsCount = pointsOrigin.length;

      const targetPoints: number[][] = [];
      const pulse = (kx: number, ky: number) => {
        for (let i = 0; i < pointsOrigin.length; i++) {
          targetPoints[i] = [];
          targetPoints[i][0] = kx * pointsOrigin[i][0] + width / 2;
          targetPoints[i][1] = ky * pointsOrigin[i][1] + height / 2;
        }
      };

      const particles: any[] = [];
      for (let i = 0; i < heartPointsCount; i++) {
        const x = rand() * width;
        const y = rand() * height;
        particles[i] = {
          vx: 0,
          vy: 0,
          speed: rand() + 5,
          q: ~~(rand() * heartPointsCount),
          D: 2 * (i % 2) - 1,
          force: 0.2 * rand() + 0.7,
          trace: Array.from({ length: traceCount }, () => ({ x, y })),
        };
      }

      const config = {
        traceK: 0.6,
        timeDelta: 0.02,
      };

      let textAlpha = 0;
      const textMaxAlpha = 1;

      const drawText = (time: number) => {
        textAlpha += 0.002;
        if (textAlpha > textMaxAlpha) textAlpha = textMaxAlpha;

        const scale = 1 + 0.05 * Math.sin(time * 0.5);
        ctx.save();

        // Premier texte : "Ma Juliette"
        ctx.fillStyle = `rgba(255, 0, 0, ${textAlpha})`;
        ctx.font = `${48 * scale}px "Arial Black"`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("Ma Juliette", width / 2, height / 2 + 250);

        // Deuxième texte : "4 mois ensemble"
        ctx.font = `${32 * scale}px "Arial Black"`;
        ctx.fillText("4 mois", width / 2, height / 2 + 310);

        ctx.restore();
      };

      let time = 0;
      const loop = () => {
        const n = -Math.cos(time);
        pulse((1 + n) * 0.5, (1 + n) * 0.5);
        time += config.timeDelta;

        ctx.fillStyle = "rgba(0,0,0,0.1)";
        ctx.fillRect(0, 0, width, height);

        ctx.beginPath();
        particles.forEach((particle) => {
          const q = targetPoints[particle.q];
          const dx = particle.trace[0].x - q[0];
          const dy = particle.trace[0].y - q[1];
          const length = Math.sqrt(dx * dx + dy * dy);

          if (length < 10) {
            if (rand() > 0.95) particle.q = ~~(rand() * heartPointsCount);
            else {
              if (rand() > 0.99) particle.D *= -1;
              particle.q = (particle.q + particle.D) % heartPointsCount;
              if (particle.q < 0) particle.q += heartPointsCount;
            }
          }

          particle.vx += (-dx / length) * particle.speed;
          particle.vy += (-dy / length) * particle.speed;
          particle.trace[0].x += particle.vx;
          particle.trace[0].y += particle.vy;
          particle.vx *= particle.force;
          particle.vy *= particle.force;

          for (let k = 0; k < particle.trace.length - 1; k++) {
            const T = particle.trace[k];
            const N = particle.trace[k + 1];
            N.x -= config.traceK * (N.x - T.x);
            N.y -= config.traceK * (N.y - T.y);
          }

          particle.trace.forEach((t: { x: number; y: number }) =>
            ctx.rect(t.x, t.y, 2, 2) // Agrandir les points (3x3 au lieu de 1x1)
          );
        });
        ctx.fillStyle = "rgba(255, 0, 0, 0.3)";
        ctx.fill();

        drawText(time);
        window.requestAnimationFrame(loop);
      };

      loop();
    };

    init();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} />;
};

export default HeartCanvas;
